<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Pesanan'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/default-medicine.svg"
                      
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col">
                <table class="table mb-3" v-if="dataLoaded">
                  <tr>
                    <td><strong>Kode Transaksi</strong></td>
                    <td>{{ data.id }}</td>
                  </tr>
                  <!-- <tr>
                    <td><strong>Tanggal</strong></td>
                    <td>{{ data.display_created_at }}</td>
                    
                  </tr> -->
                  <tr>
                    <td><strong>Pelanggan</strong></td>
                    <td>{{ data.billing.first_name + " " + data.billing.last_name }}</td>
                    <!-- <td>{{data.product}}</td> -->
                  </tr>
                  <tr>
                    <td><strong>Telepon</strong></td>
                    <td>{{ data.billing.phone }}</td>
                    <!-- <td>{{data.product}}</td> -->
                  </tr>
                  
                  <tr>
                    <td><strong>Produk</strong></td>
                    <td>
                      <div
                        v-for="(product,index) in data.line_items.length"
                        :key="index"
                      >
                        <li>
                          {{ data.line_items[index].name +" ( "+ data.line_items[index].quantity +" X Rp "+parseInt( data.line_items[index].price).toLocaleString('id-ID') +" )"}}
                        </li>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total Produk</strong></td>
                    <td>{{ "Rp "+parseInt(data.total-data.shipping_total).toLocaleString('id-ID') }}</td>
                  </tr>
                  <tr>
                    <td><strong>Biaya Pengiriman</strong></td>
                    <td>{{ "Rp "+parseInt(data.shipping_total).toLocaleString('id-ID') }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>{{ "Rp "+parseInt(data.total).toLocaleString('id-ID') }}</td>
                  </tr>
                  <tr>
                    <td><strong>Metode Pembayaran</strong></td>
                    <td>{{ data.payment_method_title }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat Pelanggan</strong></td>
                    <td>{{ data.billing.address_1 +", "+ data.billing.city +", Kode Pos: "+ + data.billing.postcode }}</td>
                    <!-- <td>{{data.product}}</td> -->
                  </tr>
                  <tr>
                    <td><strong>Alamat Pengiriman</strong></td>
                    <td>{{ data.billing.address_2 +", "+ data.billing.city +", Kode Pos: "+ + data.billing.postcode }}</td>
                    <!-- <td>{{data.product}}</td> -->
                  </tr>
                  <tr>
                    <td><strong>Pengiriman</strong></td>
                    <td>{{ data.shipping_lines[0].method_title }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>
                      <i class="fas fa-circle text-primary mr-2" v-if="data.status == 'Diproses'"></i>
                      <!-- <i class="fas fa-circle"></i> -->
                      <i class="fas fa-circle text-warning mr-2" v-if="data.status == 'Menunggu Pembayaran'"></i>
                      <i class="fas fa-circle text-info mr-2" v-if="data.status == 'Sedang Dikirim'"></i>
                      <i class="fas fa-circle text-danger mr-2" v-if="data.status == 'Gagal'"></i>
                      <i class="fas fa-check-circle text-success mr-2" v-if="data.status == 'Selesai'"></i>
                      <i class="fas fa-times-circle text-danger mr-2" v-if="data.status == 'Batal'"></i>
                      <i class="fas fa-circle text-primary mr-2" v-if="data.status == 'Terbayar'"></i>
                      {{ data.status }}</td>
                  </tr>
                </table>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import module from '@/core/modules/CrudModule.js'
import Table from '@/component/beds/Table.vue'
// import { set } from 'vue/types/umd'

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      dataLoaded : false,
      data:{},
      // data: {
      //   name:"",
      //   description:"",
      //   bed_category_id:"",
      //   bed_category_name:"",
      // },
      manipulateBtn:false,
    }
  },

  methods:{
    async get(){
      // this.data = await module.get('get-order-status/'+ this.$route.params.id)
      // console.log(this.data.transaction_id)
      // let dataDetail = await module.get('order-status-ecommerce/'+ this.data.transaction_id)
       this.data = await module.get(
          `wp-order-item/${this.$route.params.id}`
        );


      if (this.data == null) {
        // Redirect To List
        this.$router.push("/order/list");
      }else{
        if(this.data.status == "processing"){
          this.data["status"] = "Diproses"
        }else if(this.data.status == "on-hold"){
          this.data["status"] = "Menunggu Pembayaran"
        }else if(this.data.status == "completed"){
          this.data["status"] = "Selesai"
        }else if(this.data.status == "failed"){
          this.data["status"] = "Gagal"
        }else if(this.data.status == "pending payment"){
          this.data["status"] = "Pembayaran Ditunda"
        }else if(this.data.status == "cancelled"){
          this.data["status"] = "Batal"
        }else if(this.data.status == "shipping"){
          this.data["status"] = "Sedang Dikirim"
        }else if(this.data.status == "paid"){
          this.data["status"] = "Terbayar"
        }
      // if (this.data == null) {
      //   // Redirect To List
      //   this.$router.push("/order/list");
      // }else{
      //   if(this.data.current_status == "processing"){
      //     this.data["status"] = "Diproses"
      //   }else if(this.data.current_status == "on-hold"){
      //     this.data["status"] = "Menunggu Pembayaran"
      //   }else if(this.data.current_status == "completed"){
      //     this.data["status"] = "Selesai"
      //   }else if(this.data.current_status == "failed"){
      //     this.data["status"] = "Gagal"
      //   }else if(this.data.current_status == "pending payment"){
      //     this.data["status"] = "Pembayaran Ditunda"
      //   }else if(this.data.current_status == "cancelled"){
      //     this.data["status"] = "Batal"
      //   }else if(this.data.current_status == "shipping"){
      //     this.data["status"] = "Sedang Dikirim"
      //   }else if(this.data.current_status == "paid"){
      //     this.data["status"] = "Terbayar"
      //   }

        let c
        // this.data["product_total_amount"] = 0
        
        // for(c=0;c<this.data.price.length;c++){
        //   this.data["product_total_amount"] = parseInt(this.data["product_total_amount"]) + (parseInt(this.data.price[c])*parseInt(this.data.quantity[c]))
        // }

        // this.data["customer_name"] = dataDetail["customer_name"]
        // this.data["customer_phone"] = dataDetail["customer_phone"]
        // this.data["product"] = dataDetail["product"]
        // this.data["quantity"] = dataDetail["quantity"]
        // this.data["price"] = dataDetail["price"]
        // this.data["total_amount"] = dataDetail["total_amount"]
        // this.data["customer_address"] = dataDetail["customer_address"]
        // this.data["payment_method_title"] = dataDetail["payment_method_title"]

        // console.log("data nihh",this.data)
        this.dataLoaded = true
      }
    },

    // // access management
    // async setActiveMenu(){

    //  let access_right_user = window.localStorage.getItem("access_right_display")
    //  let access_right = JSON.parse(access_right_user)
    
    // let a
    // for(a = 0; a < access_right.length; a++){
    //   
      
    //   if(access_right[a] == "1302"){
    //     this.manipulateBtn = true
    //   }
      
    // }
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Toko Online", route: "" },
      { title: "Daftar Pesanan", route: "/order/list" },
      { title: "Detail" },
    ])
    this.get()
    // this.setActiveMenu()
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>